<template>
  <div>
    <Debug
      label="Device"
      :data="deviceInfo"
      :force-show="true"
      :expanded="true"
    />
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import utilities from '@/mixins/utilities';

export default {
  mixins: [
    utilities, //
  ],

  computed: {
    ...sync([
      'device/device', //
    ]),

    deviceInfo() {
      return {
        bowser: this.device.bowser,
      };
    },
  },
};
</script>
